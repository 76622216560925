// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import isUndefined from 'lodash/isUndefined'
import { connect } from 'react-redux'

import queryString from 'query-string'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Form from 'antd/lib/form'
import '@bodhi-project/antrd/lib/4.10.3/form/style/css'

import Steps from 'antd/lib/steps'
import '@bodhi-project/antrd/lib/4.10.3/steps/style/css'

import Button from 'antd/lib/button'
import '@bodhi-project/antrd/lib/4.10.3/button/style/css'

import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Name from '../form-fields/name'
import Email from '../form-fields/email'
import Phone from '../form-fields/phone-without-dialcodes'

import StreetAddress from '../form-fields/street-address'
import AptOrSuite from '../form-fields/apt-or-suite'
import City from '../form-fields/city'
import State from '../form-fields/state'
import PostalCode from '../form-fields/postal-code'
import Country from '../form-fields/country-input'
import Organisation from '../form-fields/organisation'

import Comment from '../form-fields/comment'
import Submit from '../form-fields/submit'

import onSubmit from '../form-methods/on-submit'

import forwardRef from '../extensions/forward-ref'

import List from './list'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const { Step } = Steps

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** ShoppingCart */
class ShoppingCart extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.formRef = this.props.refs.formRef

    this.state = {
      current: 0,
      max: 0,
      loading: false,
      resetLoading: false,
      formValues: {},
      confirmed: false,
      confirmedId: '',
      confirmedStatus: '',
    }

    this.onChange = this.onChange.bind(this)
    this.goNext = this.goNext.bind(this)
    this.goPrev = this.goPrev.bind(this)
    this.reset = this.reset.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.updateValues = this.updateValues.bind(this)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    if (typeof window !== 'undefined') {
      const { conf, location: { search } = {} } = this.props
      const { formConf } = conf
      const { onReset = () => console.log('form reset') } = formConf

      if (search) {
        const parsed = queryString.parse(search)
        const { id = '', status = '' } = parsed
        this.formRef.current.resetFields()
        onReset()
        this.setState({
          confirmed: true,
          confirmedId: id,
          confirmedStatus: status,
          current: 5,
        })
      }
    }
  }

  /** [description] */
  onChange(current) {
    const nextCurrent = current
    const { max, confirmed } = this.state

    if (confirmed === false) {
      if (nextCurrent <= max) {
        this.setState({
          current,
        })

        if (typeof window !== 'undefined') {
          console.log('scroll onchange')
          setTimeout(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }, getRandomArbitraryInt(10, 30))
        }
      }
    }
  }

  /** setLoading */
  setLoading(loading) {
    this.setState({ loading })
  }

  /** updateValues */
  updateValues(changedValues, allValues) {
    this.setState({ formValues: allValues })
  }

  /** reset */
  reset() {
    const { conf } = this.props
    const { formConf } = conf
    const { onReset = () => console.log('form reset') } = formConf

    this.setState({ resetLoading: true })
    setTimeout(() => {
      this.formRef.current.resetFields()
      onReset()
      this.setState({ current: 0, resetLoading: false })
    }, getRandomArbitraryInt(50, 70))
  }

  /** [description] */
  goNext() {
    const { current } = this.state
    const next = current + 1

    if (typeof window !== 'undefined') {
      console.log('scroll next')
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }, getRandomArbitraryInt(10, 30))
    }

    if (current === 0) {
      this.setState({ current: next, max: next })
    }

    if (current === 1) {
      this.formRef.current
        .validateFields(['name', 'email', 'phone', 'organisation'])
        .then((values) => {
          this.setState({
            current: next,
            max: next,
          })
        })
        .catch((errorInfo) => {
          // console.log('errorInfo', errorInfo)
        })
    }

    if (current === 2) {
      this.formRef.current
        .validateFields([
          'streetAddress',
          'aptOrSuite',
          'city',
          'state',
          'postalCode',
          'country',
        ])
        .then((values) => {
          this.setState({
            current: next,
            max: next,
          })
        })
        .catch((errorInfo) => {
          // console.log('errorInfo', errorInfo)
        })
    }

    if (current === 3) {
      this.setState({
        current: next,
        max: next,
      })
    }
  }

  /** [description] */
  goPrev() {
    const { current } = this.state
    const prev = current - 1
    if (typeof window !== 'undefined') {
      console.log('scroll prev')
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }, getRandomArbitraryInt(10, 30))
    }

    this.setState({
      current: prev,
    })
  }

  /** standard renderer */
  render() {
    const {
      conf,
      onFinish,
      shoppingCartState,
      awaitingServerResponse,
      disabled,
      finishedCycle,
      serverMesage,
      requestId,
      objectsRequestedString,
      location,
    } = this.props
    const { shopConf, formConf } = conf
    const { totalCountOfObjects, totalPriceOfObjects } = shoppingCartState
    const {
      loading,
      resetLoading,
      current,
      formValues,
      confirmed,
      confirmedId,
      confirmedStatus,
    } = this.state
    const {
      allowReset = false,
      onReset = () => console.log('form reset'),
      canSubmit = true,
    } = formConf

    const { href = 'https://www.light-fish.com/en/cart' } = location

    return (
      <div
        className="xform shopping-cart objects-list-with-extended-form as-paragraph"
        style={{ display: 'flex' }}
      >
        <Steps
          current={current}
          onChange={this.onChange}
          direction="vertical"
          style={{ flex: '0 0 300px', height: 500 }}
          className="hidden-on-mobile hidden-on-tablet"
        >
          <Step title="Review" description="Review your order" />
          <Step title="Contact" description="Your contact details" />
          <Step title="Address" description="Your address details" />
          <Step title="Shipping" description="Shipping details" />
          <Step title="Pay" description="Pay online" />
          <Step title="Confirmation" description="All done..." />
        </Steps>
        <div
          style={{
            flex: '1',
          }}
        >
          {confirmed === false && (
            <Fragment>
              <Form
                id="cart"
                className="as-paragraph"
                scrollToFirstError
                ref={this.formRef}
                onFinish={onFinish}
                style={{
                  paddingLeft: 'var(--responsive-webshop-space)',
                  marginLeft: 'var(--responsive-webshop-space)',
                  borderLeft:
                    'var(--responsive-webshop-border-width) solid var(--text-color)',
                  maxWidth: 'unset',
                }}
                onValuesChange={this.updateValues}
              >
                <List
                  conf={shopConf}
                  finishedCycle={finishedCycle}
                  style={{ display: current === 0 ? 'block' : 'none' }}
                />
                <Name
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 1 ? 'block' : 'none' }}
                />
                <Email
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 1 ? 'block' : 'none' }}
                />
                <Phone
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 1 ? 'block' : 'none' }}
                />
                <Organisation
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 1 ? 'block' : 'none' }}
                />
                <StreetAddress
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 2 ? 'block' : 'none' }}
                />
                <AptOrSuite
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 2 ? 'block' : 'none' }}
                />
                <City
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 2 ? 'block' : 'none' }}
                />
                <State
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 2 ? 'block' : 'none' }}
                />
                <PostalCode
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 2 ? 'block' : 'none' }}
                />
                <Country
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 2 ? 'block' : 'none' }}
                />
                <div
                  className="as-paragraph"
                  style={{ display: current === 3 ? 'block' : 'none' }}
                >
                  <p style={{ marginBottom: '0.2rem' }}>
                    <strong>Shipping information</strong>
                  </p>
                  <p>
                    We offer free shipping in India. In case of international
                    shipping we will send you a mail with information on
                    delivery charges that you must cover. The delivery charges
                    are based on 3 main factors. First of all it depends on your
                    location. Secondly, it depends on your urgency, we offer our
                    customers the possibility of receiving the package via
                    normal post or express post. And thirdly, it depends on the
                    size and weight of your order.
                  </p>
                  <p style={{ marginBottom: '0.2rem' }}>
                    <strong>Time to ship</strong>
                  </p>
                  <p>
                    We can ship to any country that has a postal service. All
                    orders are despatched directly from our offices in India to
                    your postal address so shipping is available worldwide. Most
                    orders (unless otherwise indicated) are processed within 3-4
                    working days. For orders shipped within India it takes 3-7
                    working days until delivery. If you live in a remote area,
                    anticipate a few days more. For international orders please
                    give 10-21 days if sent via registered postal service and
                    7-10 days for EMS Speed Post. In busy times of the year or
                    in rare occasions registered parcel can take up to 4 weeks
                    to arrive. If you have not received your parcel within this
                    time please contact us so we can track your parcel for you.
                  </p>
                </div>
                <Comment
                  {...this.props}
                  conf={{ formConf }}
                  style={{ display: current === 3 ? 'block' : 'none' }}
                />
                <br style={{ display: current === 3 ? 'block' : 'none' }} />
                <div
                  className="as-paragraph message"
                  style={{ display: current === 3 ? 'block' : 'none' }}
                >
                  <p style={{ marginBottom: '0.2rem' }}>
                    <strong>Other notes</strong>
                  </p>
                  <p>
                    Please allow us 3-5 business days to responsd to your
                    request. We are a small, locally-owned independent store,
                    and will try to respond to your request as soon as possible.
                  </p>
                  {isUndefined(serverMesage) === false && (
                    <Fragment>
                      <p style={{ marginBottom: '0.2rem' }}>
                        <strong>Registering your request</strong>
                      </p>
                      <p style={{ marginBottom: 0 }}>{serverMesage}</p>
                    </Fragment>
                  )}
                </div>
              </Form>
              <div
                className="as-paragraph"
                style={{
                  display: current !== 4 ? 'flex' : 'none',
                  justifyContent: 'flex-end',
                  marginLeft: 'calc(2rem + 4px)',
                }}
              >
                <Button
                  style={{
                    display:
                      totalCountOfObjects > 0
                        ? finishedCycle === false
                          ? 'block'
                          : 'none'
                        : 'none',
                  }}
                  ghost
                  className="reset-button"
                  disabled={
                    canSubmit === false ||
                    disabled === true ||
                    awaitingServerResponse === true ||
                    finishedCycle === true
                  }
                  onClick={this.reset}
                  loading={resetLoading}
                >
                  Reset
                </Button>
                <Button
                  onClick={this.goPrev}
                  style={{
                    display:
                      totalCountOfObjects > 0
                        ? current >= 1
                          ? finishedCycle === false
                            ? 'block'
                            : 'none'
                          : 'none'
                        : 'none',
                  }}
                  ghost
                  className="previous-button"
                  disabled={
                    canSubmit === false ||
                    disabled === true ||
                    awaitingServerResponse === true ||
                    finishedCycle === true
                  }
                >
                  Previous
                </Button>
                <Submit
                  {...this.props}
                  serverMesage={serverMesage}
                  finishedCycle={finishedCycle}
                  awaitingServerResponse={awaitingServerResponse}
                  conf={{ formConf }}
                  style={{
                    display:
                      totalCountOfObjects > 0
                        ? current === 3
                          ? finishedCycle === false
                            ? 'block'
                            : 'none'
                          : 'none'
                        : 'none',
                  }}
                />
                <Button
                  onClick={this.goNext}
                  style={{
                    display:
                      totalCountOfObjects > 0
                        ? current !== 3
                          ? 'block'
                          : finishedCycle === true
                          ? 'block'
                          : 'none'
                        : 'none',
                  }}
                  className="next-button"
                >
                  Next
                </Button>
              </div>
              <form
                action="https://talampay.auroville.org/api/transactions"
                method="post"
                style={{ display: current === 4 ? 'block' : 'none' }}
              >
                <input
                  type="hidden"
                  name="customer_name"
                  value={
                    isUndefined(formValues.name) === false
                      ? formValues.name
                      : ''
                  }
                />
                <input
                  type="hidden"
                  name="customer_email"
                  value={
                    isUndefined(formValues.email) === false
                      ? formValues.email
                      : ''
                  }
                />
                <input
                  type="hidden"
                  name="customer_phone"
                  value={
                    isUndefined(formValues.phone) === false
                      ? formValues.phone
                      : ''
                  }
                />
                <input
                  type="hidden"
                  name="source"
                  value="https://www.light-fish.com/"
                />
                <input
                  type="hidden"
                  name="purpose"
                  value={objectsRequestedString}
                />
                <input type="hidden" name="source_order_id" value={requestId} />
                <input
                  type="hidden"
                  name="payment_account_id"
                  value="ae6f2f6b-52fe-4e4f-bf80-80cbd22adcbf"
                />
                <input
                  type="hidden"
                  name="amount"
                  value={totalPriceOfObjects}
                />
                <input type="hidden" name="currency" value="INR" />
                <input type="hidden" name="return_url" value={href} />
                <div
                  className="as-paragraph forward-message"
                  style={{
                    paddingLeft: 'var(--responsive-webshop-space)',
                    marginLeft: 'var(--responsive-webshop-space)',
                    borderLeft:
                      'var(--responsive-webshop-border-width) solid var(--text-color)',
                    maxWidth: 'calc(33rem + 4px)',
                    // maxWidth: 'unset',
                  }}
                >
                  <p style={{ marginBottom: '0.2rem' }}>
                    <strong>Payment gateway</strong>
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    You will be redirected to the Auroville Payment gateway. If
                    your browser or extension detects this as a pop-up, please
                    allow the pop-up.
                  </p>
                </div>
                <div
                  className="as-paragraph"
                  style={{
                    paddingLeft: 'var(--responsive-webshop-space)',
                    marginLeft: 'var(--responsive-webshop-space)',
                    // borderLeft: '4px solid var(--text-color)',
                    maxWidth: 'calc(33rem + 4px)',
                    textAlign: 'right',
                  }}
                >
                  <button className="payment-button" type="submit">
                    Finish
                  </button>
                </div>
              </form>
            </Fragment>
          )}
          {confirmed === true && (
            <div
              style={{
                paddingLeft: '1rem',
                marginLeft: '1rem',
                borderLeft:
                  'var(--responsive-webshop-border-width) solid var(--text-color)',
                maxWidth: 'unset',
              }}
            >
              <p>
                We have registered your request, and your payment is{' '}
                {confirmedStatus}.
              </p>
              <p>
                Your order request number is:{' '}
                <strong style={{ textDecoration: 'underline' }}>
                  {confirmedId}
                </strong>
                . Please include this if you're writing or calling us about this
                order.
              </p>
              <p>
                We will write to you once we ship your order. Thank you for
                ordering with us. You can now continue browsing our website.
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({})
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------- Compose & Exports
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ShoppingCartWithGoogleForm = compose(
  forwardRef,
  onSubmit,
  withState
)(ShoppingCart)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ShoppingCartWithGoogleForm
