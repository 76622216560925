// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { graphql } from 'gatsby'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

import forEach from 'lodash/forEach'
import join from 'lodash/join'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import '@bodhi-project/components/lib/form/reset.less'

import smallKey from '@bodhi-project/components/lib/methods/smallKey'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/4.10.3/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/4.10.3/col/style/css'

import Division from '@bodhi-project/components/lib/division'
import '@bodhi-project/components/lib/division/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import WebShop from '../components/webshop-form'
import '../components/webshop-form/style.less'

import seoHelper from '../methods/seoHelper'

import { resetShoppingCart } from '../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const formRef = React.createRef()
// const { Fragment } = React
const pageData = {
  pageTitle: 'Shopping Cart',
  nakedPageSlug: 'cart',
  pageAbstract:
    'We hope that this website and web shop can help strengthen the fragile ecosystem and margins around bookselling and keep Prisma Books independent from big tech. Being independent and self-reliant will allow us to keep on telling the story of Auroville as we have been for the last 33 years.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    launch: file(relativePath: { eq: "launch.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const {
    shoppingCartState: {
      objectsList,
      objectsCounts,
      totalCountOfObjects,
      totalPriceOfObjects,
    },
    resetShoppingCart,
  } = props

  const objectsRequested = []
  forEach(objectsList, (object) => {
    objectsRequested.push(
      `${object.title} (${objectsCounts[object.routeSlug]}${
        objectsCounts[object.routeSlug] > 1 ? ' numbers' : ''
      }, at ₹${object.price}${
        objectsCounts[object.routeSlug] > 1 ? ' each' : ''
      })`
    )
  })
  const objectsRequestedString = join(objectsRequested, '; ')
  const requestId = smallKey()

  const shopConf = {
    valueModifiers: [
      {
        text: '5% discount',
        valueModifier: (x) => x * 0.95,
      },
    ],
  }

  const formConf = {
    macroId:
      'AKfycbwGI_rVJsOzBjJ6wC6Ab_MXhYbuLs0Biv2aYb1Ov4eTsABEeTdrnYLhUyExyoQeJNi2kg',
    includeTitle: true,
    includeExtra: true,
    fields: {
      name: {
        title: 'Name',
        extra: 'Required',
      },
      email: {
        title: 'Email',
        extra: 'Required',
      },
      phone: {
        title: 'Phone',
        extra: 'Required',
      },
      streetAddress: {
        title: 'Street Address',
        extra: 'Required',
      },
      aptOrSuite: {
        title: 'Apt. Or Suite',
        extra: 'Optional',
        rules: [
          {
            validator: (_, value) => {
              return Promise.resolve()
            },
          },
        ],
      },
      city: {
        title: 'City',
        extra: 'Required',
      },
      state: {
        title: 'State',
        extra: 'Required',
      },
      postalCode: {
        title: 'Postal Code',
        extra: 'Required',
      },
      country: {
        title: 'Country',
        extra: 'Required',
      },
      organisation: {
        title: 'Organisation',
        extra: 'Optional',
        rules: [
          {
            validator: (_, value) => {
              return Promise.resolve()
            },
          },
        ],
      },
      comment: {
        title: 'Comment',
        extra:
          'Optional. A special request – should we gift wrap the package? Or, should we deliver between certain hours? Or...',
        rules: [
          {
            validator: (_, value) => {
              return Promise.resolve()
            },
          },
        ],
      },
    },
    trace: {
      success:
        'We have registered your request. Please click on Next to proceed to the payment gateway.',
    },
    allowReset: false,
    onReset: () => resetShoppingCart(),
    canSubmit: totalCountOfObjects > 0,
    alsoInclude: {
      totalObjects: `${totalCountOfObjects}`,
      totalQuotedPrice: `${totalPriceOfObjects}`,
      requested: objectsRequestedString,
      requestId,
    },
  }

  return (
    <ConfigProvider prefixCls="wtf">
      <StandardPageWrapper seoData={seoData} {...props}>
        <Division Row={Row} Col={Col} threeQuarters>
          <div>
            <WebShop
              conf={{ shopConf, formConf }}
              refs={{ formRef }}
              requestId={requestId}
              objectsRequestedString={objectsRequestedString}
              location={props.location}
            />
          </div>
          <aside className="hidden-on-mobile hidden-on-tablet hidden-on-desktop">
            <h2 className="block-heading">Discounts</h2>
            <p>
              If you buy directly from the LiGHT-FiSH Web Shop you will recieve
              a flat <strong>5% discount</strong> on your purchase.
            </p>
          </aside>
        </Division>
      </StandardPageWrapper>
    </ConfigProvider>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({
    resetShoppingCart(payload) {
      dispatch(resetShoppingCart(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
