// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import isUndefined from 'lodash/isUndefined'
import isEmpty from 'validator/lib/isEmpty'
import trim from 'validator/lib/trim'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Form from 'antd/lib/form'
import '@bodhi-project/antrd/lib/4.10.3/form/style/css'

import Input from 'antd/lib/input'
import '@bodhi-project/antrd/lib/4.10.3/input/style/css'

import Tooltip from 'antd/lib/tooltip'
import '@bodhi-project/antrd/lib/4.10.3/tooltip/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import getTitle from '../form-methods/get-form-item-title'
import getPlaceholder from '../form-methods/get-form-item-placeholder'
import getInfo from '../form-methods/get-form-item-info'
import getExtra from '../form-methods/get-form-item-extra'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Organisation */
const Organisation = ({
  fieldName = 'organisation',
  conf: {
    formConf: {
      includeTitle = false,
      includePlaceholders = false,
      includeSuffix = false,
      includeExtra = false,
      fields = { [fieldName]: {} },
      dateFormat = 'Do MMM, YYYY',
    },
  } = {},
  fieldConf = fields[fieldName],
  givenRules = fieldConf.rules,
  defaultRules = [
    {
      validator: (_, value) => {
        if (isUndefined(value)) {
          return Promise.reject('Please enter your organisation')
        } else {
          const trimmed = trim(value)

          if (isEmpty(trimmed) === true) {
            return Promise.reject('Please enter your organisation')
          } else {
            return Promise.resolve()
          }
        }
      },
    },
  ],
  awaitingServerResponse = false,
  finishedCycle = false,
  style = {},
}) => (
  <Fragment>
    {includeTitle === true && (
      <p className="field-title" style={style}>
        <span>{getTitle(fieldName, fieldConf)}</span>
      </p>
    )}
    <Form.Item
      name={fieldName}
      className={`field as-paragraph input-field--${fieldName}`}
      rules={typeof givenRules === 'undefined' ? defaultRules : givenRules}
      validateTrigger={['onChange', 'onBlur']}
      hasFeedback
      extra={
        includeExtra ? (
          <Fragment>{getExtra(fieldName, fieldConf)}</Fragment>
        ) : null
      }
      style={style}
    >
      <Input
        placeholder={
          includePlaceholders ? getPlaceholder(fieldName, fieldConf) : ' '
        }
        suffix={
          includeSuffix ? (
            <Tooltip title={getInfo(fieldName, fieldConf)}>i</Tooltip>
          ) : null
        }
        disabled={awaitingServerResponse === true || finishedCycle === true}
      />
    </Form.Item>
  </Fragment>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Organisation
