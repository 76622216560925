// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import forEach from 'lodash/forEach'
import map from 'lodash/map'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Trail, animated, config } from 'react-spring/renderprops'
import CountUp from 'react-countup'

import Plus from '../svg/plus'
import Minus from '../svg/minus'
import Cross from '../svg/cross'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import {
  popObjectFromShoppingCart,
  decreaseCountOfObjectInShoppingCart,
  increaseCountOfObjectInShoppingCart,
} from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** List */
const List = (props) => {
  const {
    shoppingCartState: {
      objectsList,
      objectsCounts,
      totalCountOfObjects,
      thatTotalPriceOfObjects,
      thisTotalPriceOfObjects,
    },
    increaseCountOfObjectInShoppingCart,
    decreaseCountOfObjectInShoppingCart,
    popObjectFromShoppingCart,
    conf,
    style = {},
    finishedCycle,
  } = props
  const { valueModifiers = [] } = conf

  let modifiedThatTotalPriceOfObjects = thatTotalPriceOfObjects
  let modifiedThisTotalPriceOfObjects = thisTotalPriceOfObjects

  if (Object.keys(valueModifiers).length > 0) {
    forEach(valueModifiers, ({ valueModifier }) => {
      modifiedThatTotalPriceOfObjects = valueModifier(
        modifiedThatTotalPriceOfObjects
      )
      modifiedThisTotalPriceOfObjects = valueModifier(
        modifiedThisTotalPriceOfObjects
      )
    })
  }

  return (
    <Fragment>
      {totalCountOfObjects === 0 ? (
        <div className="as-paragraph empty-objects-list" style={style}>
          <p style={{ marginBottom: 0 }}>
            The shopping cart is empty. Please make a selection.
          </p>
        </div>
      ) : (
        <div className="as-paragraph objects-list" style={style}>
          <p>
            You have <strong>{totalCountOfObjects}</strong> items in the
            shopping cart. Add more or submit your request here.
          </p>
          <div style={{ marginBottom: '1rem' }}>
            <div
              style={{
                display: 'flex',
                padding: '0px 0px calc(0.625 * 1rem) 0px',
                // background: 'var(--gray-2)',
                borderBottom: '2px solid var(--text-color)',
              }}
            >
              <p
                style={{
                  flex: 60,
                  margin: 0,
                  paddingRight: 'calc(0.38 * 1rem)',
                }}
              >
                <strong style={{ fontWeight: 600 }}>Object</strong>
              </p>
              <p
                style={{
                  flex: 20,
                  margin: 0,
                  paddingRight: 'calc(0.38 * 1rem)',
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  flex: 5,
                  margin: 0,
                  paddingRight: 'calc(0.38 * 1rem)',
                }}
              >
                <strong style={{ fontWeight: 600 }}>#</strong>
              </p>
              <p style={{ flex: 15, margin: 0 }}>
                <strong style={{ fontWeight: 600 }}>Est. Value</strong>
              </p>
            </div>
            <Trail
              native
              items={objectsList}
              from={{ opacity: 0, x: -10 }}
              to={{ opacity: 1, x: 0 }}
              config={config.default}
              keys={(item) => item.routeSlug}
            >
              {(item, index) => ({ x, opacity }) => (
                <animated.div
                  style={{
                    opacity,
                    transform: x.interpolate((d) => `translate3d(${d}%,0,0)`),
                    display: 'flex',
                    padding: 'calc(0.38 * 1rem) 0px',
                    borderTop: index !== 0 && '1px solid var(--text-color)',
                    // background:
                    //   index % 2 === 0 ? 'var(--yellow-0)' : 'var(--indigo-0)',
                  }}
                  className="item"
                >
                  <p
                    style={{
                      flex: 60,
                      margin: 0,
                      paddingRight: 'calc(0.38 * 1rem)',
                    }}
                  >
                    {item.title}
                  </p>
                  <p
                    style={{
                      flex: 20,
                      margin: 0,
                      paddingRight: 'calc(0.38 * 1rem)',
                    }}
                    className="actions"
                  >
                    <span
                      className={finishedCycle === true ? 'active' : 'inactive'}
                      onClick={() =>
                        increaseCountOfObjectInShoppingCart(item.routeSlug)
                      }
                      title="Click to add one unit"
                    >
                      <Plus />
                    </span>
                    &nbsp;
                    <span
                      className={finishedCycle === true ? 'active' : 'inactive'}
                      onClick={() =>
                        decreaseCountOfObjectInShoppingCart(item.routeSlug)
                      }
                      title="Click to remove one unit"
                    >
                      <Minus />
                    </span>
                    &nbsp;
                    <span
                      className={finishedCycle === true ? 'active' : 'inactive'}
                      onClick={() => popObjectFromShoppingCart(item.routeSlug)}
                      title="Click to remove this object"
                    >
                      <Cross />
                    </span>
                  </p>
                  <p
                    style={{
                      flex: 5,
                      margin: 0,
                      paddingRight: 'calc(0.38 * 1rem)',
                    }}
                  >
                    {objectsCounts[item.routeSlug]}
                  </p>
                  <p style={{ flex: 15, margin: 0 }}>
                    ₹{item.price * objectsCounts[item.routeSlug]}
                  </p>
                </animated.div>
              )}
            </Trail>
          </div>
          <p style={{ marginBottom: 'calc(0.625 * 1rem)' }}>
            <strong style={{ fontWeight: 600 }}>Putting it together</strong>
          </p>
          <div
            style={{
              display: 'flex',
              padding: 'calc(0.38 * 1rem) 0px',
              // background: 'var(--gray-2)',
              borderTop: '2px solid var(--text-color)',
            }}
          >
            <p
              style={{
                flex: 60,
                margin: 0,
                paddingRight: 'calc(0.38 * 1rem)',
              }}
            >
              {Object.keys(valueModifiers).length > 0 ? 'Added up' : 'Total'}
            </p>
            <p
              style={{
                flex: 20,
                margin: 0,
                paddingRight: 'calc(0.38 * 1rem)',
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                flex: 5,
                margin: 0,
                paddingRight: 'calc(0.38 * 1rem)',
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                flex: 15,
                margin: 0,
              }}
            >
              <CountUp
                start={thatTotalPriceOfObjects}
                end={thisTotalPriceOfObjects}
                prefix="₹"
                duration={1.5}
              />
            </p>
          </div>
          {Object.keys(valueModifiers).length > 0 && (
            <Fragment>
              {map(valueModifiers, ({ text, valueModifier }) => (
                <Fragment>
                  <div
                    style={{
                      display: 'flex',
                      padding: 'calc(0.38 * 1rem) 0px',
                      // background: 'var(--gray-2)',
                      borderTop: '1px solid var(--text-color)',
                    }}
                  >
                    <p
                      style={{
                        flex: 60,
                        margin: 0,
                        paddingRight: 'calc(0.38 * 1rem)',
                      }}
                    >
                      {text}
                    </p>
                    <p
                      style={{
                        flex: 20,
                        margin: 0,
                        paddingRight: 'calc(0.38 * 1rem)',
                      }}
                    >
                      &nbsp;
                    </p>
                    <p
                      style={{
                        flex: 5,
                        margin: 0,
                        paddingRight: 'calc(0.38 * 1rem)',
                      }}
                    >
                      &nbsp;
                    </p>
                    <p
                      style={{
                        flex: 15,
                        margin: 0,
                      }}
                    >
                      {modifiedThisTotalPriceOfObjects -
                        thisTotalPriceOfObjects <
                        0 && '(-)'}
                      <CountUp
                        start={
                          thatTotalPriceOfObjects -
                          modifiedThatTotalPriceOfObjects
                        }
                        end={
                          thisTotalPriceOfObjects -
                          modifiedThisTotalPriceOfObjects
                        }
                        prefix="₹"
                        duration={1.5}
                      />
                    </p>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          )}
          <div
            style={{
              display: 'flex',
              padding: 'calc(0.38 * 1rem) 0px',
              // background: 'var(--gray-2)',
              borderTop: '1px solid var(--text-color)',
            }}
          >
            <p
              style={{
                flex: 60,
                margin: 0,
                paddingRight: 'calc(0.38 * 1rem)',
              }}
            >
              {Object.keys(valueModifiers).length > 0
                ? 'Total (objects and est. value)'
                : 'X'}
            </p>
            <p
              style={{
                flex: 20,
                margin: 0,
                paddingRight: 'calc(0.38 * 1rem)',
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                flex: 5,
                margin: 0,
                paddingRight: 'calc(0.38 * 1rem)',
              }}
            >
              {totalCountOfObjects}
            </p>
            <p
              style={{
                flex: 15,
                margin: 0,
              }}
            >
              <CountUp
                start={modifiedThatTotalPriceOfObjects}
                end={modifiedThisTotalPriceOfObjects}
                prefix="₹"
                duration={1.5}
              />
            </p>
          </div>
        </div>
      )}
    </Fragment>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({
    increaseCountOfObjectInShoppingCart(payload) {
      dispatch(increaseCountOfObjectInShoppingCart(payload))
    },
    decreaseCountOfObjectInShoppingCart(payload) {
      dispatch(decreaseCountOfObjectInShoppingCart(payload))
    },
    popObjectFromShoppingCart(payload) {
      dispatch(popObjectFromShoppingCart(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------- Compose & Exports
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedList = compose(withState)(List)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedList
