// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const PlusSvgSymbol = props => {
  return (
    <svg
      width={900}
      height={900}
      viewBox="0 0 900 900"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M73.102 337.09h263.982V73.11c0-80.572 225.82-80.572 225.82 0V337.09h263.981c80.571 0 80.571 225.818 0 225.818H562.903v263.982c0 80.572-225.819 80.572-225.819 0V562.91H73.102c-80.57 0-80.57-225.818 0-225.818z"
          fill="#000"
          fillRule="nonzero"
        />
        <path d="M0 0h900v900H0z" />
      </g>
    </svg>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default PlusSvgSymbol
